@charset "utf-8";

$themeColor: #fff;

@import 'swiper/swiper-vars.scss';
@import 'swiper/swiper.scss';
@import 'swiper/components/a11y/a11y.scss';
@import 'swiper/components/controller/controller.scss';
@import 'swiper/components/effect-coverflow/effect-coverflow.scss';
@import 'swiper/components/effect-cube/effect-cube.scss';
@import 'swiper/components/effect-fade/effect-fade.scss';
@import 'swiper/components/effect-flip/effect-flip.scss';
@import 'swiper/components/lazy/lazy.scss';
@import 'swiper/components/navigation/navigation.scss';
@import 'swiper/components/pagination/pagination.scss';
@import 'swiper/components/scrollbar/scrollbar.scss';
@import 'swiper/components/thumbs/thumbs.scss';
@import 'swiper/components/zoom/zoom.scss';

.swiper-container {
  transition: opasity 0.8s ease;
  &._bgiUse {
    height: 100%;
    .swiper-slide {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      & > img {
        font-family: 'object-fit: cover;';
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
      // &:not(.swiper-slide-active) {
      //   opacity: 0.5;
      // }
    }
  }
  &.thumbnails {
    .swiper-slide {
      &:not(.swiper-slide-active) {
        opacity: 0.5;
      }
    }
  }

  @media (max-width: 992px) {
    padding: 0;
  }
  @media (max-width: 576px) {
    padding: 0;
    .swiper-button-prev,
    .swiper-button-next {
      height: 40px;
      width: 20px;
    }
  }
}
.swiper-pagination span {
  margin: 0 0.5rem;
}
.swiper-pagination._square {
  .swiper-pagination-bullet {
    background-color: rgba(#555, 0.8);
    border-radius: 0;
    display: inline-block;
    font-size: 12px;
    height: 3px;
    margin: 0 2px;
    opacity: 1;
    text-align: center;
    transition: all 0.5s ease;
    width: 40px;
  }
  .swiper-pagination-bullet-active {
    background-color: $themeColor;
    height: 5px;
  }
}
.swiper-dock {
  align-items: center;
  display: flex;
  justify-content: center;
  > .swiper-container {
    order: 2;
    width: calc(100% - 100px);
    img {
      width: 100%;
    }
  }
  > .prev,
  > .next {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    width: 100px;
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
  > .prev {
    background-image: url("data:image/svg+xml;charset=utf8,%3csvg id='layer1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 170 300'%3e%3cdefs%3e%3cstyle%3e.cls-1{fill:none;stroke:%23555;stroke-miterlimit:10;stroke-width:6.445px;}%3c/style%3e%3c/defs%3e%3cpolyline class='cls-1' points='156.774 293.548 13.226 150 156.774 6.452'/%3e%3c/svg%3e");
    order: 1;
  }
  > .next {
    background-image: url("data:image/svg+xml;charset=utf8,%3csvg id='layer1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 170 300'%3e%3cdefs%3e%3cstyle%3e.cls-1{fill:none;stroke:%23555;stroke-miterlimit:10;stroke-width:6.445px;}%3c/style%3e%3c/defs%3e%3cpolyline class='cls-1' points='13.226 6.452 156.774 150 13.226 293.548'/%3e%3c/svg%3e");
    order: 3;
  }
  @media (max-width: 1100px) {
    > .swiper-container {
      width: calc(100% - 70px);
    }
    > .prev,
    > .next {
      width: 70px;
    }
  }
  @media (max-width: 576px) {
    > .swiper-container {
      width: calc(100% - 40px);
    }
    > .prev,
    > .next {
      width: 40px;
    }
  }
}
